import { mapActions } from 'vuex'

import debounce from 'lodash/debounce'

export default {
  props: ['driveId', 'folderId'],
  watch: {
    driveId() {
      this.updateDrive()
    },
    folderId() {
      this.updateDrive()
    }
  },
  mounted() {
    this.updateDrive = debounce(async function () {
      //
      // Update of the current folder (each time route params changed)
      //
      await this.openFolder({
        driveId: this.driveId,
        folderId: this.folderId
      })
    })
    this.updateDrive()
  },
  methods: {
    ...mapActions('drive', ['openFolder'])
    //
  }
}
