<template>
  <common-dialog
    ref="dialog"
    :title="title"
    ok="create"
    :trigger="add"
    :args="args"
    :notif="notif"
  >
    <v-form ref="form">
      <v-text-field v-model="name" label="Name"></v-text-field>
    </v-form>
  </common-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import CommonDialog from './CommonDialog'

export default {
  name: 'CreateDialog',
  props: {
    placeholder: String,
    folder: Boolean
  },
  data: () => ({
    name: 'New score',
    type: 'file',
    local: 'local'
  }),
  methods: {
    ...mapActions('drive', ['add']),

    open: function (type, local) {
      if (!type) type = this.folder ? 'folder' : 'file'
      this.type = type
      if (this.placeholder) {
        this.name = this.placeholder
      } else {
        this.name = type == 'file' ? 'New score' : 'New folder'
      }
      if (local) this.local = local || true
      this.$refs.dialog.open()
    }
  },
  computed: {
    title() {
      return this.type == 'file' ? 'New score' : 'New folder'
    },
    args() {
      return {
        name: this.name,
        type: this.type,
        local: this.local
      }
    },
    notif() {
      return `'${this.name}' has been created`
    }
  },
  components: {
    CommonDialog
  }
}
</script>
