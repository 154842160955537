<template>
  <v-sheet class="drive" @contextmenu="openContextMenu(current, $event)">
    <create-dialog ref="createDialog" />
    <sample-dialog ref="sampleDialog" />
    <context-menu ref="contextMenu" type="drive" />
    <breadcrumb-bar v-if="driveId" />

    <div ref="dummy" class="dummy pa-5">
      <v-card class="pa-5">
        <v-icon class="mr-4">{{ dragIcon }}</v-icon>
        <v-label>{{ dragName }}</v-label>
      </v-card>
    </div>

    <!-- CONTENT -->
    <div class="content" :style="contentStyle">
      <!-- 
        ROOT ITEMS -->
      <div class="root-items" v-if="!driveId">
        <v-btn
          class="root-card"
          v-for="(item, itemIndex) in items"
          :key="itemIndex"
          tile
          elevation="0"
          :to="getItemTo(item)"
        >
          <app-icon class="large" :value="getItemIcon(item)" />
          <div class="root-title">{{ getItemLabel(item) }}</div>
          <div class="root-details">{{ getDetails(item) }}</div>
        </v-btn>
      </div>

      <!-- SPINNER -->
      <app-spinner v-else-if="driveUpdating" :delay="250" />

      <!-- EMPTY MESSAGE -->
      <app-message v-else-if="!items.length"> Ce dossier est vide </app-message>

      <!-- LIST ITEMS -->

      <v-list v-else>
        <v-list-item
          draggable="true"
          :class="getItemClass(item)"
          v-for="(item, itemIndex) in items"
          :ref="item.id"
          :key="itemIndex"
          link
          :to="getItemTo(item)"
          v-on:dragover="dragover(item, $event)"
          v-on:dragleave="dragleave(item, $event)"
          v-on:dragstart="dragstart(item, $event)"
          v-on:drop="dragdrop(item, $event)"
          v-on:dragend="dragend(item, $event)"
          @contextmenu="openContextMenu(item, $event)"
        >
          <template>
            <v-list-item-icon>
              <v-icon>{{ getItemIcon(item) }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-label>{{ getItemLabel(item) }}</v-label>
            </v-list-item-content>
            <v-list-item-content class="ml-2">
              <score-tags :tags="item.tags" />
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list>
    </div>

    <!-- ADD FOLDER OR FILE BUTTON -->

    <v-speed-dial
      v-if="current.canHaveChildren"
      :style="fabStyle"
      bottom
      right
      direction="top"
      class="dial"
      transition="slide-y-reverse-transition"
      v-model="fab"
    >
      <template v-slot:activator>
        <v-btn fab class="trigger" v-model="fab" :color="fab ? '' : 'accent'">
          <v-icon v-if="!fab">mdi-plus</v-icon>
          <v-icon v-else>mdi-close</v-icon>
        </v-btn>
      </template>
      <v-btn
        small
        fab
        v-for="(item, index) in fabButtons"
        @click="openCreateDialog(item)"
        :key="index"
        :color="item.color"
      >
        <v-icon>{{ item.icon }}</v-icon>
      </v-btn>
    </v-speed-dial>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex'

import driveDragMixin from '../../mixins/driveDragMixin'
import driveUpdateMixin from '../../mixins/driveUpdateMixin'

import CreateDialog from '../dialog/CreateDialog.vue'
import SampleDialog from '../dialog/SampleDialog.vue'
import AppMessage from '../misc/AppMessage.vue'
import ContextMenu from '../menu/ContextMenu.vue'
import BreadcrumbBar from '../toolbar/BreadcrumbBar.vue'
import ScoreTags from '../score/ScoreTags.vue'
import AppSpinner from '../misc/AppSpinner.vue'
import AppIcon from '../misc/AppIcon.vue'

export default {
  name: 'Drive',
  mixins: [driveDragMixin, driveUpdateMixin],
  components: {
    CreateDialog,
    SampleDialog,
    AppMessage,
    ContextMenu,
    BreadcrumbBar,
    ScoreTags,
    AppSpinner,
    AppIcon
  },
  props: {
    driveId: String,
    scoreId: String
  },
  data: () => ({
    fab: null,
    fabButtons: [
      { icon: 'mdi-file-plus', type: 'file', color: 'accent' },
      { icon: 'mdi-folder-plus', type: 'folder', color: 'accent' }
    ]
  }),
  computed: {
    ...mapGetters('drive', ['current', 'settings', 'driveUpdating']),

    items() {
      return (this.current && this.current.items) || []
    },

    fabStyle() {
      return `right:calc(${this.$vuetify.application.right}px + var(--app-gutter-md)`
    },

    contentStyle() {
      return this.driveId ? `margin-top: 48px;` : ''
    }
  },
  watch: {
    items(value) {
      const isLocal = this.driveId === 'local'
      if (value && !value.length && isLocal) {
        this.openSampleDialog()
      }
    }
  },
  methods: {
    getItemIcon(item) {
      if (item.id === 'remote') return 'storage-cloud'
      if (item.id === 'local') return 'storage-local'
      else return item.isFile ? 'mdi-music-box-outline' : 'mdi-folder'
    },
    getItemLabel(item) {
      if (item.id === 'remote') return 'Cloud'
      else if (item.id === 'local') return 'Local storage'
      else return item.name
    },
    getItemTo(item) {
      if (!item.path) return ''
      if (item.isFolder) return `/drive/${item.path}`
      else return `/score/${item.path}`
    },
    getDetails(item) {
      if (item.isLocalRoot) {
        return 'Your files available on this device (and only on this one). No account is required.'
      } else {
        return 'Your files on the SongJelly cloud. You must have an account and be logged in.'
      }
    },

    getItemClass() {
      const result = ['item']
      return result
    },

    openCreateDialog(item) {
      this.$refs.createDialog.open(item.type)
    },

    openSampleDialog() {
      this.$refs.sampleDialog.open()
    },

    openContextMenu(item, event) {
      event.stopImmediatePropagation()
      this.$refs.contextMenu.open(item, event)
    }
  }
}
</script>

<style scoped>
.v-list-item__content {
  flex: initial !important;
}

.app-breadcrumb {
  position: fixed;
  background-color: var(--app-color-layout);
  z-index: 1;
}

.content {
  flex: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}

/* ROOT ITEMS */

.root-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  /* margin: var(--app-gutter-md); */
}

.root-card {
  margin: var(--app-gutter-md);
  border: solid 1px var(--app-color-line);
  border-radius: 5px;
  min-width: 25vw !important;
  height: initial !important;
  max-height: 80vh;
  justify-content: center;
  background-color: var(--app-color-paper) !important;
  align-items: flex-start;
  display: flex;
  padding: var(--app-gutter-lg) var(--app-gutter-md) !important;
}

.root-card /deep/ .v-btn__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: calc(100vw - var(--app-gutter-md) * 4);
}
.root-title {
  margin-top: var(--app-gutter-md);
}
.root-details {
  margin-top: var(--app-gutter-md);
  font-size: small;
  opacity: 0.7;
  max-width: 300px;
  flex: 1;
  white-space: initial;
  text-transform: initial;
  font-weight: initial;
}

/* ITEMS */

.v-list {
  flex: 1;
}

/* MESSAGE */

.message {
  min-height: 400px;
  display: flex;
  background-color: initial !important;
  align-items: center;
  justify-content: center;
  color: var(--app-color-info);
  flex: 1;
}
/* DRAGDROP */

.dummy {
  position: fixed;
  top: -200px;
  left: 0;
  z-index: 100;
}
.dragged {
  opacity: 0.25;
}

/* DIAL */

.dial {
  position: fixed;
  z-index: 100;
  transition: right var(--app-transition-function)
    var(--app-transition-duration);
}
.dial .trigger {
  position: relative;
}
</style>
