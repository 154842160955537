<template>
  <div class="message info">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppMessage'
}
</script>

<style scoped>
.message {
  min-height: 400px;
  display: flex;
  background-color: initial !important;
  align-items: center;
  justify-content: center;
  color: var(--app-color-info);
  flex: 1;
}
</style>
