<template>
  <div class="peer-view">
    <peer-dialog ref="peerDialog" />
    <div v-if="!isOn" class="message">
      <div>You are not connected</div>
      <v-btn class="mt-4" color="accent" @click="openPeer">Connect</v-btn>
    </div>
    <div v-else>
      <player locked />
      <score-footer />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Player from '../score/Player.vue'
import ScoreFooter from '../score/ScoreFooter.vue'
import PeerDialog from '../dialog/PeerDialog.vue'

export default {
  name: 'Peer',
  components: { Player, ScoreFooter, PeerDialog },
  computed: {
    ...mapGetters('peer', ['peerStatus', 'peerError']),

    isOn() {
      return this.peerStatus === 'on'
    }
  },
  mounted() {
    if (!this.isOn) {
      this.openPeer()
    }
  },
  methods: {
    openPeer() {
      this.$refs.peerDialog.open(true)
    }
  }
}
</script>

<style scoped>
.peer-view {
  position: absolute;
  width: 100%;
  top: 0px;
  bottom: 0;
  overflow: hidden;
}
.message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.player {
  position: absolute;
  width: 100%;
  top: 0px;
  bottom: 24px;
  height: inherit;
}
.score-bar {
  position: absolute;
  bottom: 0;
  height: 24px;
  left: 0;
  right: 0;
}
</style>
