<template>
  <common-dialog
    ref="dialog"
    :trigger="proceed"
    title="Samples"
    ok="Import"
    width="300"
  >
    <div>Your local storage is empty. Do you want to import sample scores?</div>
  </common-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import CommonDialog from './CommonDialog'

export default {
  name: 'SampleDialog',
  methods: {
    ...mapActions('drive', ['fromJson']),

    open: function () {
      this.$refs.dialog.open()
    },

    async proceed() {
      const resp = await fetch('/db/SongJellySample.json')
      const json = await resp.json()
      console.log('IMPORT SAMPLES', json)
      await this.fromJson(json)
    }
  },

  components: {
    CommonDialog
  }
}
</script>
